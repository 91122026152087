import {get, merge, omit, toNumber, toString } from 'lodash-es';

export default [
	{
		path: '/account/',
		name: 'account/accountView',
		meta: { title: 'Аккаунт организации' },
		props: route => ({
			filter: merge(omit(get(route, 'query'), ['page', 'size', 'tab'])),
			page: toNumber(get(route, 'query.page', 1)),
			size: toNumber(get(route, 'query.size', 20)),
			tab: toString(get(route, 'query.tab', 'account')),
		}),
		component: () => import('@/views/account/AccountView/AccountView'),
	},
	{
		path: '/account/application/:application',
		name: 'account/accountApplicationView',
		props: route => ({
			id: toString(get(route, 'params.application')),
		}),
		meta: { title: 'Заявка на создание Аккаунта Организации' },
		component: () => import('@/views/account/AccountApplicationView/AccountApplicationView'),
	},
	{
		path: '/account/user/:user',
		name: 'account/accountUserView',
		props: route => ({
			id: toString(get(route, 'params.user')),
		}),
		meta: { title: 'Аккаунт организации' },
		component: () => import('@/views/account/AccountUserView/AccountUserView'),
	},
	{
		path: '/account/user/:user',
		name: 'account/accountUserCreateView',
		props: route => ({
			id: toString(get(route, 'params.user')),
		}),
		meta: { title: 'Ручное создание Аккаунта организации' },
		component: () => import('@/views/account/AccountUserCreateView/AccountUserCreateView'),
	},
	{
		path: '/account/user/:user/bindAccount',
		name: 'account/applicationBindAccount',
		props: route => ({
			id: toString(get(route, 'params.user')),
			filter: merge(omit(get(route, 'query'), ['page', 'size'])),
			page: toNumber(get(route, 'query.page', 1)),
			size: toNumber(get(route, 'query.size', 20)),
		}),
		meta: { title: 'Сменить владельца Аккаунта Организации' },
		component: () => import('@/views/account/AccountUserBindView/AccountUserBindView'),
	},
	{
		path: '/account/:catchAll(.*)',
		redirect: { name: 'user/ProfileView' }
	},
];
